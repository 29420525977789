/**
 * CSS styles for CLinkPager.
 *
 * @author Qiang Xue <qiang.xue@gmail.com>
 * @link http://www.yiiframework.com/
 * @copyright Copyright &copy; 2008-2010 Yii Software LLC
 * @license http://www.yiiframework.com/license/
 * @version $Id: pager.css 1678 2010-01-07 21:02:00Z qiang.xue $
 * @since 1.0
 */

.pager {
  text-align:center;
}

ul.yiiPager
{
  font-size:18px;
  border:0;
  margin:0;
  padding:0;
  line-height:100%;
  display:inline;
}

ul.yiiPager li
{
  display:inline;
}

ul.yiiPager a:link,
ul.yiiPager a:visited
{
  font-weight:normal;
  color:#fff;
  margin: 0px 6px;
  text-decoration: none;
  border-bottom: 1px solid #fff;
}

ul.yiiPager .page a
{
  font-weight:normal;
}

ul.yiiPager a:hover
{
  border: 0;
  text-decoration: none;
}

ul.yiiPager .selected a
{
  background: #ffc200;
  color:#000;
  font-weight: normal;
  text-decoration: none;
  border: 0;
  padding: 0 3px;
}

ul.yiiPager .next a
{
  background: url(../images/pointer.png) 100% 55% no-repeat;
  padding-right: 15px;
  font-weight: normal;
}

ul.yiiPager .previous a
{
  background: url(../images/pointer_l.png) 0 55% no-repeat;
  padding-left: 15px;
  font-weight: normal;
}

ul.yiiPager .hidden a
{
  color:#fff;
}

/**
 * Hide first and last buttons by default.
 */
ul.yiiPager .first,
ul.yiiPager .last
{
  display:none;
}
.services-list {
  padding: 40px 0 0 40px;
}

.services-list h1 {

}

.services-list {
  @include clearfix();
  // width: 580px;

  h2 {
    color: $c-blue;
  }
  h3 {
    padding-top: 17px;
  }
  p {

    padding: 0;

    &.header {
      @include font-roboto();
      color: $c-yellow;
      font-weight: 400;
      height: 60px;
      font-size: 20px;
      text-align: center;
    }
  }
  a {
    color: $c-blue;
  }
  .img-left {
    float: left;
    margin-right: 30px;
  }

  .service-item {
    margin-bottom: 70px;
    form {
      float: left;
      margin-right: 30px;
      margin-top: 10px;
    }
  }

  .mod-grid {
        height: 358px;
    margin-left: 15px;
    width: 240px;
    float: left;
    &.card {
      .card-title {
        color: $c-yellow;
        font-family: 'Open Sans', sans-serif;
      }
      img {
        @include filter(/*blur(10px)*/ grayscale(100%));
        opacity: 0.3;
        //@include filter();
        @include transition(all 1.0s ease-in-out);

      }

      &:hover {
        img {
          opacity: 1;
          @include filter(grayscale(0%));
        }
      }
    }
  }


  .service-item-grid {
    text-align: center;
    /* overflow: hidden; */
    float: left;
    width: 250px;
    border: 1px solid green;
    height: 300px;
    border-radius: 90px;
    padding: 5px;
    margin-left: 15px;
    margin-bottom: 90px;
    margin-right: 15px;
    a {
      img {
        @include filter(grayscale(50%));
      }
      &:hover {
        @include animation(scale 1.0s ease-in, slide 2.0s ease);
        img {

          @include filter(grayscale(0%));
        }
      }
    }

    img {
      width: 250px;
    }

  }

}

.faq {
  padding: 40px 0 0 40px;

}
.faq {
  .left {
    width: 500px;
    float: left;
    span {
      clear: both;
      font-weight: bold;
      display: block;
    }
  }

  .right {
    width: 300px;
    float: right;
    padding-right: 70px;
  }

  .thumbs_block {
    a {
      color: $c-blue;
      text-decoration: none;
      border-bottom: 1px dashed $c-blue;
      line-height: 30px;
      &:hover {
        text-decoration: none;
        border-bottom: none;
      }
    }
    .answer_hidden {
      height: 0;
    }

    .answer_visible {
      height: auto;
    }
    overflow: hidden;
    padding-top: 12px;
    p, li {
      padding: 0;
      margin: 0;
      overflow: hidden;
    }
  }

}



/* shark */
#canvas-shark-container {
  position: absolute;
  /*   z-index: 1000;*/
  background: transparent;
  display: block;
}

#shark-object {
  position: absolute;
  z-index: 100;
  display: block;
  width: 183px;
  height: 259px;
  background-image: url("/images/symbols/anim/shark.png");
  cursor: pointer;
}

div#shark-title {
  position: relative;
  top: 285px;
  border-radius: 5px;
  font-size: 17px;
  text-align: center;
  border: 4px solid #ffc200;
}

$btn-color:#3498db;
$btn-color2:#2980b9;
$b3:#3cb0fd;
$b4:#3498db;

$btn-radius:28px;
.btn {
  background: $btn-color;
  @include background-image(linear-gradient(bottom,$btn-color,$btn-color2));

  border-radius: $btn-radius;
  text-shadow: 2px 2px 4px #666666;

  -webkit-box-shadow: 1px 1px 4px #8c8c8c;
  -moz-box-shadow: 1px 1px 4px #8c8c8c;
  box-shadow: 1px 1px 4px #8c8c8c;

  font-family: Arial;
  color: #ffffff;
  font-size: 16px;

  padding: 5px 20px 5px 20px;

  border: solid #1f628d 1px;
  text-decoration: none;
}

.btn:hover {
  background: $b3;
  @include background-image(linear-gradient(bottom,$b3,$b4));
  text-decoration: none;
}

/* franchise ads */
.franchise-block {
  border: 1px solid rgba(26,96,66,1);
  position: relative;
  cursor: pointer;
  /* padding: 10px;*/
  padding-top: 5px;
  padding-bottom: 15px;
  margin-top: -55px;
  text-align: center;
  width: 600px;
  margin: 0 auto;
  height: 80px;

  background-image: url("/images/symbols/ORIG/clipped-bckg.png");
  /*background-size: 4000px 148px;*/

  background-size: 3150px 110px;
  /* background-color: rgba(23,76,122,1);*/
  /* opacity: 0.7;*/
}
.franchise-block a {
  font-size: 60px;
  font-family: 'Ubuntu', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  visibility: hidden;
  text-shadow: 1px 1px 5px rgba(255, 255, 255, 0.5);

}

.franchise-block p {
  padding: 0px;
  margin-top: 5px;
  font-size: 25px;
  visibility: hidden;
  font-family: 'Roboto', sans-serif;
}

.franchise-block #anime-1 {
  display: block;
  height: 101px;
  top: 15px;
  display: none;
  position: absolute;
  background-image: url(/images/shark/shark.png);
  width: 120px;
}



/*#inner_footer, footer {*/

$f: "red,green,yelllow,gray";
$flamp-widget: '/assets/widget-micro/images/icon-green.png';
$fw-green: url(http://widget.flamp.ru/assets/widget-micro/images/icon-green.png);

footer {
  height: 160px;
  width: 100%;

  #footer_content {
    width: 100%;
    position: relative;

    #footer_info {

      width: 250px;
      position: absolute;
      top: 50px;
      left: 10%;
    }

    #footer_share {
      width: 250px;
      position: absolute;
      top: 60px;
      left: 40%;

      .social {
        padding-top: 10px;
        a {
          margin-right: 20px;
          i {
            background-color: blueviolet;
            height: 45px;
            text-align: center;
            width: 45px;
            display: inline-block;
            line-height: 45px;
            border-radius: 14px;
          }

          &.flamp-widget {
            background-repeat: no-repeat;
            background-image:url("//widget.flamp.ru/assets/widget-micro/images/icon-yellow.png");

          }
        }
      }

    }
    #footer_dev {
      width: 250px;
      position: absolute;
      top: 50px;
      left: 70%;
      img {
        position: relative;
        bottom: 40px;
        left: 125px;
        display: block;
      }

    }
  }
}



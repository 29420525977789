/** LAYOUT **/

$bckg-idx: "/images/bg/idx-man-laser.png";
$bckg-inner: "/images/bg/inner-flares.png";



body {
  width: 100%;
  font-family: Arial;
  font-size: 14px;
  color: $white;

  @include background(
                  url($bckg-inner) top right no-repeat,
                  url(../images/bg/bottom-fluids.png) bottom left no-repeat,
                  url("/images/bg/gradient-repeat.png") top repeat-x,
                  rgb(0,15,21)
  );

  &#page-index {
    @include background(
                    url($bckg-idx) top right no-repeat,
                    url(../images/bg/bottom-fluids.png) bottom left no-repeat,
                    url("/images/bg/gradient-repeat.png") top repeat-x,
                    rgb(0,15,21)
    );
  }

}

.container {
  min-width: $screen-min-width;
  max-width: $screen-max-width;
  margin: 0 auto;
  padding: 0;

}

#content {
  //overflow: hidden;
}




.content-block {
  width: 100%;
  overflow: hidden;
  padding-bottom: 50px;

  .left {
    width: $max-width-left;
  }
  @include clearfix();
}



#content-index {
  .left {
    width: $max-width-left;
  }
}

#page-one-column {
  width: 100%;
  float: none;
}
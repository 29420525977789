
#content-index {
  width: 100%;
  //height: 400px;
  @include clearfix();
  //overflow: hidden;
  //position: relative;
  div.img_block {
        position: relative;
    /* top: 40px; */
    left: 40px;
    margin-top: 70px;
  }
  div.txt_block {
        width: 700px;
    position: relative;
    /* top: 140px; */
    left: 40px;
  }
}
#top-two-hits {
      width: 760px;
    position: relative;
    height: 300px;


  .service-block {
    width: 340px;
    float: left;
    margin: 20px 0 0 40px;
    .img_block {
      width: 150px;
      float: left;
    }
    .txt_block {
      width: 190px;
      float: left;
      margin-top: 10px;
    }
    h2 {
      @include font-roboto();
    }
  }

}



div#top-sales-container {
      height: 335px;
    width: 760px;
    overflow: hidden;
    position: relative;
    padding-left: 40px;
    /* margin-top: 10px; */
    text-align: center;
  
  h3 {
    color:$c-yellow1;
    font-size: 30px;
    margin-bottom:0px;
  }

  #top-sales {
      display: flex;
    margin-top: 0px;
          .sale {
             width: 180px;
          height: 240px;
          padding-top: 20px;
          display: block;
          margin-left: 2px;
          margin-right: 2px;
          position: relative;
            .header {
              color:$c-yellow1;
              height: 30px;
              margin-bottom: 25px;
              font-weight: bold;
              font-size: 21px;
            }

            .content {
              padding-top: 15px;

              a {
                text-decoration: none;
                border-bottom: 1px dashed $c-yellow1;

                &.no-border {
                  border-bottom: none;
                  text-decoration: none;
                }

                &.bottom-stick {
                  display: block;
                  position: absolute;
                  bottom: 15px;
                  width: 80px;
                  left: 48px;
                }

                &:hover {
                  border-bottom: none;
                }
              }
              
            }

      }
  }
}

div#top-sales {

}



#content-index-inner {
  height: 520px;
    width: 100%;
    position: relative;
}  



.order-form {
  padding: 40px 0 0 40px;

  .left {
    width: 460px;
  }
  .right {
    width: 430px;
    padding-right: 70px;
  }


}

.our_form,.avatar-form {

  width: 454px;
  div {
    height: 49px;
    padding: 0;
    &.area {
      width: 454px;
      height: 150px;
      padding: 0;
    }
    input {
      width: 258px;
      height: 20px;
      margin: 10px 0 0 10px;
      background: none;
      border: 2px solid #6c7174;

      &.button {
        margin: 10px 0 0 8px;

      }
    }
    textarea {
      width: 258px;
      height: 125px;
      margin: 10px 0 0 10px;
      background: none;
      border: 2px solid #6c7174;
      background: url(../images/form/field_lines_bg.png) 99% 97% no-repeat;
    }
    &.error {
      background: url(../images/form/field_error.png);


      input {
        border: 2px solid #950000;

      }
    }


  }
  input.btn {

  }

}



#slider_box {
  background: url(../images/form/slide_box.png) no-repeat 0px 0px;
  position: relative;
  margin-top: 30px;
  margin-left: 10px;
  span {
    font-size: 14px;
    font-style: italic;
    position: absolute;
    right: 100px;
    top: -4px;
  }
}


.text-content-container {

  width: 600px;
  padding: 40px 0 0 40px;
  a {
    color: $c-blue;

  }
  h2 {
    margin-bottom: 12px;
  }
  li {
    line-height: 24px;
  }
  margin-bottom: 12px;

}


.content-block {
  ul, ol {
    padding-left: 10px;
  }

  ol, ol li {
    list-style-type: decimal-leading-zero;
    list-style-position: inside;
  }

  ul, ul li {
    list-style-type: disc;
    list-style-position: inside;
  }
  ul {

    li {
      margin-top: 5px;
    }
  }
  h3 {
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 120%;
  }
}
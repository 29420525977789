
.ui-widget {
  font-family: Verdana, Arial, sans-serif;
  font-size: 1.1em;
}

.ui-widget-content {
  border: none;
  color: #222222;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: none;
  background: url(../images/form/slider.png) 0px 0px no-repeat;
  font-weight: normal;
  color: #ffffff;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
}

.ui-slider {
  position: relative;
  text-align: left;
}

.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 27px;
  height: 28px;
  cursor: default;
}

.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  display: block;
  border: 0;
  background-position: 0 0;
}

.ui-slider-horizontal {
  height: 7px;
  width: 117px;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -9px;
  margin-left: -13px;
}

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}

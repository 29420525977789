
@mixin font-roboto {
  font-family: "Roboto", sans-serif;
}

@mixin font-ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

@mixin font-opensans {
  font-family: $opensans-font;
}
/** PRICE TABLE **/
table.color {
  width: 100%;
}

table.color th {
  background-color: #6989B1;
  border: 1px solid yellowGreen;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

table.color td {
  border: 1px solid yellowGreen;
  padding: 15px;
}

td a.fancybox {
  color: white;
}


#points {
  width: 195px;
  height: 200px;
  margin-right: 40px;
  float: right;
  background: url(../images/cup.png) top left no-repeat;
  div.record {
    width: 120px;
    height: 90px;
    padding: 35px 0 0 80px;
  }
  .all_points {
    width: 100%;
    padding-left: 30px;
  }
}
.points {
  width: 195px;
  background: url(../images/cup.png) top left no-repeat;
  div.show-count {
    font-size: 18px;
    font-weight: bold;
    font-style: italic;
    color: $c-yellow;
  }
  span, a {
    font-style: italic;

  }
  a {
    font-weight: bold;
    color: $white;
  }

  .record {
    height: 90px;
    padding: 35px 0 0 85px;
  }

  .sum_points {
    padding-left: 20px;

    /** SCORE COUNTER **/

    .counter {
      width: 190px;
      height: 36px;
      margin-bottom: 5px;
      img {
        width: 24px;
        height: 36px;
        padding: 0;
        margin: 0;
      }
    }

  }
}


@mixin action-c($top, $left) {
  width: 250px;
  position: absolute;
  top: $top;
  left: $left;
}

.inner_addinfo {
    width: 100%;
    height: 550px;
   /**/
}

.icons-actions-grid {
  width: 100%;
  height: 460px;
  &.header {
     background: url(../images/header-dopolnitelnie-uslugi.png) top no-repeat;
  }
  .row {
    width: 100%;
    position: relative;

    .action-1 {
      @include action-c(154px,10%);
    }

    .action-2 {
      @include action-c(130px,40%);
    }
    .action-3 {
      @include action-c(85px,70%);
    }

    .action-4 {
      width: 250px;
      position: absolute;
      top: 130px;
      left: 10%;
    }

    .action-5 {
      width: 250px;
      position: absolute;
      top: 137px;
      left: 40%;
    }

    .action-5 {
      img {
        margin-left: 0px;
      }
    }

    .action-6 {
      position: absolute;
      right: 0;
      top: 140px;
    }

  }
}

.action-1 {
  width: 250px;
  position: absolute;
  top: 130px;
  left: 10%;
}

.action-2 {
  width: 250px;
  position: absolute;
  top: 130px !important;
  left: 40%;
}

.action-3 {
  top: 150px !important;
}


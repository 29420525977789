input, textarea {
  color: $white;
  padding-left: 5px;
  padding-right: 5px;
}
span.pointer {
  font-size: 13px;
}
@mixin clear {
  clear: both;
}

div.clear {
  @include clearfix();
}

p {
  line-height: 18px;
  margin: 10px 0 0 0;
  &.center {
    text-align: center;
  }
}

a {
  color: $c-yellow1;
  text-decoration: underline;

  &:hover {
    text-decoration: none;

  }
  &.pointer {
    text-decoration: none;
  }

  img {
    text-decoration: none;
  }
}
h1,h2,h3,h4,h5,h6 {
  font-family:$roboto-font;
}
h1 {
  font-size: 36px;
  color: $c-yellow1;
  line-height: 36px;
  margin-bottom: 10px;
  clear: both;
  text-decoration: none;
}

h2 {
  font-size: 24px;
  line-height: 24px;
  color: $c-yellow1;
  margin: 10px 0 0 0;
  text-decoration: none;
  display: inline-block;
  position: relative;
}



@mixin header-1() {
  font-size: 36px;
  color: $c-yellow1;
  line-height: 36px;
  margin-bottom: 10px;
  clear: both;
  text-decoration: none;
}

@mixin header-2() {
  font-size: 24px;
  color: $c-blue;
  line-height: 24px;
  margin: 10px 0 0 0;
  text-decoration: none;
}

@mixin header-2a() {
  font-size: 24px;
  line-height: 24px;
  color: $c-yellow1;
  margin: 10px 0 0 0;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

@mixin header-3() {
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
  color: $c-yellow;
}

/** HEADERS H1-H3 **/
.text-content-container h1, .services-list h1, .make-order h1, .faq h1, .gallery h1 {
  //@include header-1();
}

.services-list h2, .gallery h2 {
  @include header-2();
}

#addinfo_content h2, .service-block h2, .el_usl h2, .text-content-container h2, .make-order h2, .faq h2 {
  @include header-2a();
}

.text-content-container h2 {
  clear: both;
}

#addinfo_content h2 a, .service-block h2 a, .el_usl h2 a, .text-content-container h2 a, .make-order h2 a {
  border-bottom: 2px solid $c-yellow1;
  text-decoration: none;
}

#addinfo_content h2 a:hover, .service-block h2 a:hover, .el_usl h2 a:hover, .text-content-container h2 a:hover, .make-order h2 a:hover {
  border-bottom: none;
  margin-bottom: 2px;
}

#points h3, .services-list h3 {
  @include header-3();
}


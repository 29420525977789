a.go-to-album {
  height: 20px;
  line-height: 20px;
  background: none;
  font-size: 15px;
  background: url(../images/pointer.png) 100% 65% no-repeat;
  padding-right: 15px;
  padding-left: 5px;
}

.gallery {
  padding: 40px 0 50px 40px;

  .g-items {
    padding: 20px 0 10px 0;
    div {
      margin-top: 5px;
    }
    .img_cont {
      float: left;
      margin: 15px 13px 0 0;
      a {
        text-decoration: none;
        img {
          text-decoration: none;
        }
      }
    }

  }
  .pager {
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
  }
}
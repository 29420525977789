

/** HEADER **/
$menu-max-width:871px;
header {
  width: 100%;
  height: 130px;
  margin-top:-15px;
  position: relative;
  #logo {
    width: 170px;
    height: 87px;
    position: absolute;
    top: -1px;
    left: 15px;
  }
  #phone {
    position: absolute;
    top: 30px;
    right: 40px;
    span.prephone {
      font-size: 16px;
    }
    span.pnumber {
      font-size: 26px;
      font-weight: bold
    }

    a {
      margin-left: 40px;
    }
  }


  /** MENU **/

  #menu_container {
    position: relative;
    top: 80px;
    left: 230px;
    width:$menu-max-width;
    #main_menu {
     // width: 100%;
      width: 840px;
      height: 15px;
      list-style-type: none;
      clear: both;
      li {
        float: left;
        a {
          background: none;
          display: block;
          font-size: 15px;
          font-weight: normal;
          color: $white;
          text-decoration: underline;
          padding: 4px;
          margin: 0 5px;
          &:hover {
            background: $c-yellow;
            color: $black;
            text-decoration: none;
          }
        }

        &.active {
          a {
            background: $c-yellow;
            color: $black;
            text-decoration: none;
          }
        }
      }
    }


    #path {
      height: 20px;
      padding-top: 10px;
      width: 100%;
      list-style-type: none;
      clear: both;
      li {
        height: 20px;
        display: block;
        float: left;
        background: url(../images/pointer.png) 100% 55% no-repeat;
        padding-right: 15px;
        &.cur {
          background: none;
          padding-right: 0;

          a {
            text-decoration: none;
          }
        }
        a {
          height: 20px;
          line-height: 20px;
          background: none;
          font-size: 15px;
          font-weight: normal;
          padding-top: 4px;
          margin: 0 5px;
        }
      }
    }
  }
}

@import url("//fonts.googleapis.com/css?family=Open+Sans:700|Roboto:400|Ubuntu:700&amp;subset=cyrillic");

//
// Variables
// --------------------------------------------------
$max-width-left:760px;

//== Colors
//
$c-yellow:#ffb900;
$c-yellow1:#ffc200;
$c-blue: #377eff;
$screen-min-width: 980px;
$screen-max-width: 1280px;

$roboto-font: "Roboto";
$ubuntu-font: "Ubuntu";
$opensans-font:'Open Sans',sans-serif;


